import { render, staticRenderFns } from "./EditPoster.vue?vue&type=template&id=a4c140f6&"
import script from "./EditPoster.vue?vue&type=script&lang=ts&"
export * from "./EditPoster.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports